import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Layout from "components/shared/Layout";
import { featuresData } from "./featuresData";
import FeaturesListItem from "./FeaturesListItem";
import CTASection from "components/shared/CTASection";

const FeaturesPage = () => {
  return (
    <Layout>
      <Content>
        <Helmet title="Features | PhotoInvoice" />
        <h1>Features</h1>

        <List>
          {featuresData.map((feature) => (
            <FeaturesListItem key={feature.title} feature={feature} />
          ))}
        </List>

        <Tail>
          And we are building more all the time...{" "}
          <a href="https://www.facebook.com/photoinvoice">
            Follow us on Facebook
          </a>{" "}
          to stay up to date!
        </Tail>
      </Content>

      <CTASection
        title="Create your first hassle-free invoice"
        subtitle="Test your first invoice in seconds, for free."
      />
    </Layout>
  );
};

export default FeaturesPage;

const Content = styled.div`
  width: 750px;
  max-width: 96%;
  margin: 0 auto;
`;

const List = styled.ul`
  list-style: none;
`;

const Tail = styled.p`
  margin-top: 50px;
  margin-bottom: 100px;
  line-height: 1.5;
`;
