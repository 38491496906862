import React from "react";
import Helmet from "react-helmet";

import Layout from "components/shared/Layout";
import {
  Wrapper,
  Title,
  Content,
  ImgCentered,
  Divider,
} from "styles/PageStyles";

import descriptionFieldImg from "./images/description-field.jpg";
import descriptionOnInvoiceImg from "./images/description-on-invoice.jpg";
import descriptionSettingsImg from "./images/description-settings.jpg";
import invoiceFormCustomImg from "./images/invoice-form-custom.jpg";
import invoiceCustomImg from "./images/invoice-custom.jpg";
import templateImg from "./images/description-template.jpg";

export default function InvoiceDescription() {
  return (
    <Layout>
      <Helmet title="Invoice Description Field | PhotoInvoice" />
      <Wrapper>
        <Title>Invoice Description Field</Title>
        <Content>
          <p>
            Each invoice includes a description field for providing context and
            information to your client about the shoot:
          </p>
          <ImgCentered
            src={descriptionFieldImg}
            alt="Description field when creating a real estate photography invoice"
          />
          <p>This is displayed on the final invoice above the line items:</p>
          <ImgCentered
            src={descriptionOnInvoiceImg}
            alt="Description field when displaying the final real estate photography invoice"
          />
          <p>
            For real estate photographers this field is often used for the
            property address, but it can tailored to whatever purpose suits your
            business best.
          </p>
          <Divider />
          <h3>Customizing the description field</h3>
          <p>
            In your{" "}
            <a href="https://app.photoinvoice.com/settings/invoice-settings">
              Invoice Settings
            </a>{" "}
            area, you can customize the description field in two ways:
          </p>
          <ul>
            <li>Turn the field off (for all invoices)</li>
            <li>Customize the field name</li>
          </ul>
          <p>
            For instance, here we&apos;ve customized the name of the field to
            &quot;Property Address&quot;:
          </p>
          <ImgCentered
            src={descriptionSettingsImg}
            alt="PhotoInvoice settings page where the description field can be customized"
          />
          <p>And that is reflected both when creating an invoice:</p>
          <ImgCentered
            src={invoiceFormCustomImg}
            alt="Customized description field when creating a real estate photography invoice"
          />
          <p>And when the client is viewing the invoice:</p>
          <ImgCentered
            src={invoiceCustomImg}
            alt="Customized description field when displaying a real estate photography invoice"
          />

          <Divider />

          <h3 id="template">Setting Up a Description Template</h3>
          <p>
            If your invoice description always uses the same format, and you
            want to prefill any repetitive parts of it, setting up a description
            field template is a great way to save time.
          </p>

          <p>
            Any content you add into the template field will be prefilled on
            each new invoice, so you only need to edit the parts specific to
            that invoice.
          </p>

          <p>Some examples would be:</p>

          <ul>
            <li>
              If you use the description field for the shoot address, and most
              shoots have the same city, state, and zip, you could add that as
              your template.
            </li>
            <li>
              If all invoice descriptions include the labels "Shoot Address:"
              and "Other Notes:", set up those labels as the template.
            </li>
          </ul>
          <ImgCentered
            src={templateImg}
            alt="Customized description field when displaying a real estate photography invoice"
          />
          <p>
            You'll save yourself precious time and be creating invoices faster
            than ever!
          </p>

          <Divider />

          <p>
            You can label and use your description field whatever way works best
            for your business! Questions or suggestions? Shoot us an email at{" "}
            <a href="mailto:support@photoinvoice.com">
              support@photoinvoice.com
            </a>
            .
          </p>
        </Content>
      </Wrapper>
    </Layout>
  );
}
