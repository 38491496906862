import React from "react";

import Layout from "components/shared/Layout";
import { Wrapper, Title, Content, ImgCentered } from "styles/PageStyles";
import articleData from "../grow-article-data.json";
import readyToSaveImgSrc from "./canned-response-ready-to-save.png";

const CannedResponsesArticle = () => {
  const article = articleData.find(
    (article) => article.slug === "canned-responses"
  );

  return (
    <Layout>
      <Wrapper>
        <Title>{article.title}</Title>
        <Content>
          <p>
            As a real estate photographer, you can end up sending a lot of
            repeat emails: messages where the receipt is different but the
            content is VERY similar. Things like:
          </p>

          <ul>
            <li>Shoot schedule confirmations</li>
            <li>Contract agreements</li>
            <li>Pre-shoot house prep instructions</li>
            <li>Missed appointment notifications</li>
            <li>Photo delivery</li>
            <li>Invoice delivery</li>
            <li>
              Invoice reminders (though not if you’re using PhotoInvoice!)
            </li>
            <li>Thank you notes</li>
            <li>Testimonial requests</li>
          </ul>

          <p>
            If you use Gmail, there is a quick and easy way to make sending
            these repeat messages a breeze: canned responses. Canned Responses
            lets you save a message template, and reuse it over and over in the
            future.
          </p>

          <p>
            <em>
              (Note: if you’re not using Gmail, there is probably a similar
              add-on available for your email client.)
            </em>
          </p>

          <p>
            Here’s how to set it up and get started using it to make running
            your real estate photography business more efficient.
          </p>

          <h2>Set Up</h2>

          <ol>
            <li>
              In your Gmail, go to the <strong>gear icon</strong> in the upper
              right corner of your inbox, and select <strong>Settings</strong>.
            </li>
            <li>
              Click the <strong>Labs</strong> tab, located the{" "}
              <strong>Canned Responses</strong> item, and enable it.
            </li>
            <li>
              Scroll to the bottom of the page and click{" "}
              <strong>Save Changes</strong>.
            </li>
          </ol>

          <h2>Saving a Canned Response</h2>

          <p>
            Now that we’ve got the feature enabled, return to your inbox and
            click the <strong>Compose</strong> button. For our example, we will
            create a canned response to handle delivering our PhotoInvoice link
            to the customer once their photos are done processing and the
            invoice is ready. To create your first canned response:
          </p>

          <ol>
            <li>
              Use a generic, descriptive subject (for now). You’ll change this
              later when you’re actually going to send it. For now let’s use
              “Your Real Estate Photos Are Ready”.
            </li>
            <li>
              Enter in the message body. For variables that will change, I like
              to use placeholder words in ALL CAPS to remind myself to update
              those values before sending. Here’s an example of my message
              that’s ready to save for future use:
              <ImgCentered
                src={readyToSaveImgSrc}
                alt="Canned Response- ready to save"
              />
            </li>
            <li>
              To save your message template for future use, click the small
              triangle icon in the lower right, and go to{" "}
              <strong>Canned responses</strong>, and select{" "}
              <strong>New canned response…</strong> under the “Save” header. In
              the confirm box, click <strong>Save</strong>.
            </li>
          </ol>

          <h2>Using Your Canned Response</h2>

          <p>
            This couldn’t be much simpler. After you’ve opened a new message,
            click the small triangle in the lower right, and in{" "}
            <strong>Canned responses</strong> select your message. Boom! All you
            have to do now is change your variables, add a personal note if you
            want, and click send.
          </p>

          <p>
            As a real estate photographer, finding small ways to save time and
            be more efficient can make all the difference. Features like canned
            responses are a great way to level up your email game.
          </p>
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default CannedResponsesArticle;
