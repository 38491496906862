import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "components/shared/Layout";
import { colors } from "Constants";
import introPicSrc from "assets/demo/austria-mark-fin.jpg";
import hiImMarkSrc from "assets/demo/hi-im-mark.svg";
import SignupButtonLarge from "components/shared/Buttons/SignupButtonLarge";

const DemoPage = () => {
  return (
    <Layout>
      <Helmet title="Demo | PhotoInvoice" />
      <Content>
        <Title>Demo</Title>
        <IntroWrapper>
          <Intro>
            <Pic src={introPicSrc} />
            <IntroContent>
              <Script src={hiImMarkSrc} />
              <IntroText>
                I built PhotoInvoice to solve the frustrations I had as a real
                estate photographer when getting paid… hit play and I’ll show
                you around!
              </IntroText>
            </IntroContent>
          </Intro>
        </IntroWrapper>
        <PricingNote>
          Note: Our pricing has changed since this video was created! See the{" "}
          <Link to="/pricing">pricing page</Link> for the latest pricing info.
        </PricingNote>
        <Video>
          <iframe
            src="https://www.youtube.com/embed/F8Ag6eVXAt8?ecver=2"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
          />
        </Video>

        <WhatsNextWrapper>
          <WhatsNextContent>
            <h2>{"What's Next?"}</h2>
            <p>
              Signing up! It <strong>costs nothing</strong> and is{" "}
              <strong>risk free</strong>. You only pay a small fee when you get
              paid.
            </p>
            <p>
              Our <strong>free concierge migration</strong> service makes it
              even easier. Upon signup, we’ll reach out to help you get your
              customer list imported and help take care of everything necessary
              to get rolling with{" "}
              <strong>effortless real estate photography invoicing</strong>.
            </p>
          </WhatsNextContent>
        </WhatsNextWrapper>

        <SignupButtonWrapper>
          <SignupButtonLarge />
        </SignupButtonWrapper>

        <QuestionsWrapper>
          <QuestionsContent>
            <h2>Questions?</h2>
            <p>
              {`We're here to help. Send us a note and we'll get back to you in a
              jiffy.`}
            </p>
            <EmailLinkWrapper>
              <EmailLink href="mailto:info@photoinvoice.com">
                info@photoinvoice.com
              </EmailLink>
            </EmailLinkWrapper>
          </QuestionsContent>
        </QuestionsWrapper>
      </Content>
    </Layout>
  );
};

export default DemoPage;

const Content = styled.div`
  width: 1200px;
  max-width: 90%;
  margin: 0 auto 0;
`;

const Title = styled.h1`
  text-align: left;
`;

const IntroWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  max-width: 100%;
`;

const Intro = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const Pic = styled.img`
  border-radius: 100%;
  height: 150px;
  width: 150px;
  margin-right: 30px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);

  @media (max-width: 480px) {
    margin: 0;
  }
`;

const IntroContent = styled.div`
  margin-top: -20px;
  width: 550px;
  max-width: 100%;

  @media (max-width: 480px) {
    margin-top: 15px;
  }
`;

const Script = styled.img`
  margin-bottom: 10px;
  @media (max-width: 480px) {
    // text-align: center;
    margin: 0 auto 10px;
    display: block;
  }
`;

const IntroText = styled.div`
  font-size: 19px;
  color: #393939;
  line-height: 1.2;

  @media (max-width: 480px) {
    text-align: center;
    font-size: 17px;
  }
`;

const PricingNote = styled.div`
  margin-top: 30px;
  margin-bottom: 10px;
  color: #777;
  text-align: center;
`;

const Video = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const WhatsNextWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const WhatsNextContent = styled.div`
  width: 800px;
  max-width: 100%;
  font-size: 20px;
  line-height: 1.3;

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

const SignupButtonWrapper = styled.div`
  margin-top: 30px;
`;

const QuestionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0 80px;
`;

const QuestionsContent = styled.div`
  width: 800px;
  max-width: 100%;
  font-size: 20px;
  line-height: 1.3;

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;

const EmailLinkWrapper = styled.p`
  margin: 20px;
  text-align: center;
`;

const EmailLink = styled.a`
  color: ${colors.rose};
  font-size: 24px;

  @media (max-width: 400px) {
    font-size: 18px;
  }
`;
