import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { HiX } from "react-icons/hi";

import { colors } from "Constants";
import { Link } from "gatsby";

PricingFeatureItem.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isAvailable: PropTypes.bool,
  learnMoreUrl: PropTypes.string,
};

export default function PricingFeatureItem({
  text,
  icon,
  isAvailable,
  learnMoreUrl,
}) {
  const renderLearnMore = () => {
    if (!learnMoreUrl) return null;

    return (
      <LearnMore>
        <Link to={learnMoreUrl}>Learn More</Link>
      </LearnMore>
    );
  };

  return (
    <Wrapper>
      <Icon isAvailable={isAvailable}>{isAvailable ? icon() : <HiX />}</Icon>
      <Text isAvailable={isAvailable}>
        {text}
        {renderLearnMore()}
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 26px auto;
  font-size: 16px;
`;

const Icon = styled.div`
  padding-top: 2px;
  width: 50px;
  flex-shrink: 0;
  text-align: center;
  color: ${(props) => (props.isAvailable ? colors.accentGreen : "#c3c3c3")};
  font-size: 21px;
`;

const Text = styled.div`
  text-align: left;
  line-height: 1.4;
  color: ${(props) => (props.isAvailable ? "#333" : "#919191")};
`;

const LearnMore = styled.div`
  display: inline-block;
  margin-left: 4px;
  font-size: 12px;

  a {
    /* color: #a8a8a8; */
    color: ${colors.accent};
  }
`;
