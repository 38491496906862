import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";

import customWatermarkImg from "assets/features/custom-watermark.png";
import customWatermarkInvoiceImg from "assets/features/custom-watermark-invoice.png";
import Layout from "components/shared/Layout";
import CTASection from "components/shared/CTASection";

export default function CustomWatermarkPage() {
  return (
    <Layout>
      <Content>
        <Helmet title="Custom Watermarks | PhotoInvoice" />
        <h1>Custom Watermarks</h1>
        <p>What&apos;s better than automatically watermarked sample photos?</p>
        <p>Sample photos automatically watermarked with YOUR logo!</p>

        <img
          src={customWatermarkImg}
          alt="Photo Invoice real estate photography custom watermark"
        />

        <p>
          Now included in the Professional edition of PhotoInvoice, custom
          watermarks give you the chance to customize the payment experience
          even further, and gives another opportunity to promote your logo and
          brand.
        </p>
        <Spacer />
        <h3>A little background...</h3>
        <p>
          As part of our quest to help real estate photographers get paid on
          time (every time), invoices display watermarked sample photos. This
          gives your client a taste of the shoot and motivates them to submit
          payment in order to access the finals.
        </p>
        <p>
          By default, photos are watermarked with the word{" "}
          <strong>SAMPLE</strong>. This works great, but many business owners
          want to take it a step further and use their logo (or any other custom
          word or graphic) for the watermark.
        </p>
        <p>
          With the Professional edition of PhotoInvoice, you can now upload any
          custom watermark file you desire, further customizing the PhotoInvoice
          payment experience you offer to your clients.
        </p>
        <InvoiceImg
          src={customWatermarkInvoiceImg}
          alt="Photo Invoice real estate photography invoice with custom watermark"
        />
        <p>
          To get started, head over to your{" "}
          <a href="https://app.photoinvoice.com/settings/logo">
            PhotoInvoice settings
          </a>
          , or take a look at the{" "}
          <Link to="/help/custom-watermark">custom watermark tutorial</Link>.
        </p>
        <p>
          Questions? We&apos;re happy to help!{" "}
          <a href="mailto:support@photoinvoice.com">support@photoinvoice.com</a>
        </p>
      </Content>

      <CTASection
        title="Create your first hassle-free invoice"
        subtitle="Test your first invoice in seconds, for free."
      />
    </Layout>
  );
}

const Content = styled.div`
  box-sizing: border-box;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  p {
    line-height: 1.7;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
  }
`;

const Spacer = styled.div`
  height: 5px;
`;

const InvoiceImg = styled.img`
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
`;
