import React, { Component } from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

import { colors } from "Constants";
import { RoundedButtonPrimary } from "components/shared/Buttons/Buttons";

class DemoButton extends Component {
  renderButton() {
    const { type } = this.props;

    switch (type) {
      case "small": {
        return (
          <RoundedButtonPrimary>
            <Icon className="fa fa-youtube-play" /> Watch a free demo
          </RoundedButtonPrimary>
        );
      }
      case "large":
      default:
        return (
          <SignupButton>
            <IconLarge className="fa fa-youtube-play" />
            <ButtonText>
              <ButtonMain>Watch a Demo</ButtonMain>
              <ButtonSub>See PhotoInvoice in Action</ButtonSub>
            </ButtonText>
          </SignupButton>
        );
    }
  }

  render() {
    return (
      <Wrapper>
        <Link to="demo-video">{this.renderButton()}</Link>
      </Wrapper>
    );
  }
}

DemoButton.propTypes = {
  type: PropTypes.string
};

export default DemoButton;

const Wrapper = styled.div`
  display: inline-block;
  max-width: 100%;
  margin: 0 auto;
`;

const SignupButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  max-width: 100%;
  border-radius: 60px;
  background-color: ${colors.rose};
  padding: 24px;
  color: white;
  margin: 0 auto;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  transition: background-color 250ms, transform 250ms;

  &:hover {
    background-color: ${colors.roseHover};
  }

  &:active {
    transform: scale(0.99);
  }
`;

const IconLarge = styled.span`
  font-size: 48px !important;
  margin-right: 15px;

  @media (max-width: 340px) {
    // font-size: 36px !important;
    display: none !important;
  }
`;

const Icon = styled.span`
  font-size: 28px !important;
  margin-right: 10px;
`;

const ButtonText = styled.div`
  text-align: left;
`;

const ButtonMain = styled.div`
  font-weight: 900;
  font-size: 28px;

  @media (max-width: 400px) {
    font-size: 26px;
  }
`;

const ButtonSub = styled.div`
  margin-top: 4px;
  font-size: 17px;
  opacity: 0.8;

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;
