import styled from "styled-components";
import { colors } from "Constants";

export const ButtonBase = styled.button`
  display: inline-block;
  border-radius: 18px;
  padding: 0 30px;
  font-size: 19px;
  font-weight: 600;
  height: 54px;
  line-height: 54px;
  white-space: nowrap;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
  transition: transform 150ms;
  cursor: pointer;

  &:active {
    transform: scale(0.98);
  }
`;

export const PrimaryButton = styled(ButtonBase)`
  color: white;
  background-color: var(--accent);
  transition: background-color 250ms;

  &:hover {
    background-color: var(--accentHover);
  }
`;

export const OutlineButton = styled(ButtonBase)`
  border: 1px solid ${colors.rose};
  color: ${colors.rose};
  transition: background-color 250ms, color 250ms;

  &:hover {
    background-color: ${colors.rose};
    color: white;
  }
`;
