import styled from "styled-components";
import { MAX_CONTENT_WIDTH } from "Constants";

const Container = styled.div`
  width: ${MAX_CONTENT_WIDTH};
  max-width: 96%;
  margin: 0 auto;
`;

export default Container;
