import React from "react";
import styled from "styled-components";

import heroImg2 from "assets/home/downloads-unlocked.webp";
import { PrimaryButton } from "components/shared/Buttons/Buttons2";

export default function HeroSection() {
  return (
    <Wrapper>
      <Title>
        <Line1>The easiest way for</Line1>
        <REP>Real Estate Photographers</REP>
        <Line2>
          to <Highlight>stop chasing payments</Highlight>.
        </Line2>
      </Title>

      <SubTitle>
        <SubLine1>
          Invoices with watermarked samples that unlock after payment.{" "}
        </SubLine1>
        Get paid fast, every time.
      </SubTitle>

      <Img src={heroImg2} alt="Photos, files, and links unlock after payment" />

      <ButtonWrapper>
        <a href="https://app.photoinvoice.com/signup">
          <PrimaryButtonCustom>Try Your First Invoice</PrimaryButtonCustom>
        </a>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 80px 20px;
  background-color: var(--primary);

  @media (max-width: 820px) {
    padding: 60px 20px;
  }
  @media (max-width: 640px) {
    padding: 40px 15px;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 68px;

  @media (max-width: 1000px) {
    font-size: 54px;
  }
  @media (max-width: 820px) {
    font-size: 42px;
  }
  @media (max-width: 640px) {
    font-size: 32px;
  }
  @media (max-width: 460px) {
    font-size: 28px;
  }
`;

const Line1 = styled.span`
  display: block;
`;

const REP = styled.span`
  display: block;
`;

const Line2 = styled.span`
  display: block;
`;

const Highlight = styled.span`
  color: var(--accentGreen);
`;

const SubTitle = styled.div`
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 1.4;
  padding: 30px 20px 20px;
  font-size: 24px;

  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 820px) {
    padding-top: 20px;
    font-size: 19px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
  }
  @media (max-width: 460px) {
    font-size: 16px;
    padding: 10px 10px 0;
  }
`;

const SubLine1 = styled.span`
  display: block;

  @media (max-width: 720px) {
    display: inline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  @media (min-width: 680px) {
    height: 80px;
    padding: 0 50px;
    font-size: 24px;
  }
`;

const Img = styled.img`
  display: block;
  width: 800px;
  max-width: 100%;
  margin: 30px auto;
`;
