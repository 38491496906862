import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  FaFileInvoiceDollar,
  FaUsers,
  FaImages,
  FaExchangeAlt,
  FaCreditCard,
  FaChartLine,
  FaFlag,
  FaPaperPlane,
  FaBolt,
  FaCloudDownloadAlt,
  FaEnvelope,
  FaHandHoldingUsd,
  FaImage,
  FaRegEye,
} from "react-icons/fa";

import { RiVideoDownloadFill } from "react-icons/ri";

import PricingFeatureItem from "./_PricingFeatureItem";

PricingFeatureList.propTypes = {
  edition: PropTypes.string,
};

export default function PricingFeatureList({ edition }) {
  const isPremium = edition === "Professional";

  return (
    <List>
      <PricingFeatureItem
        text="Unlimited invoices"
        icon={FaFileInvoiceDollar}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Unlimited clients"
        icon={FaUsers}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Display watermarked sample photos before payment"
        icon={FaImages}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Delivery upon payment (require payment to access photos/links)"
        icon={FaExchangeAlt}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Accept payment via credit/debit card*"
        icon={FaCreditCard}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Invoice &amp; Revenue Reports"
        icon={FaChartLine}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Use your own custom domain"
        icon={FaFlag}
        isAvailable={true}
        learnMoreUrl="/features/custom-domain"
      />
      <PricingFeatureItem
        text="Deliver photos, videos, files with Dropbox, WeTransfer, Drive, and more"
        icon={FaPaperPlane}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Line item &amp; deliverable templates"
        icon={FaBolt}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Track when invoices have been viewed"
        icon={FaRegEye}
        isAvailable={true}
      />
      <PricingFeatureItem
        text="Deliver photos directly (no need for Dropbox, Drive, etc)"
        icon={FaCloudDownloadAlt}
        isAvailable={isPremium}
      />
      <PricingFeatureItem
        text="Deliver videos, PDFs, and other files directly (no need for Dropbox, Drive, etc)"
        icon={RiVideoDownloadFill}
        isAvailable={isPremium}
      />
      <PricingFeatureItem
        text="Unlimited custom email templates"
        icon={FaEnvelope}
        isAvailable={isPremium}
      />
      <PricingFeatureItem
        text="Accept tips"
        icon={FaHandHoldingUsd}
        isAvailable={isPremium}
      />
      <PricingFeatureItem
        text="Custom sample photo watermarks"
        icon={FaImage}
        isAvailable={isPremium}
        learnMoreUrl="/features/custom-watermark"
      />
    </List>
  );
}

const List = styled.ul`
  width: 95%;
  margin: 50px auto 30px;
  li {
    margin-bottom: 6px;
  }
`;
