import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Layout from "components/shared/Layout";
import CTASection from "../shared/CTASection";
import EditionsSection from "./_EditionsSection";

const PricingPage = () => {
  return (
    <Layout>
      <Helmet title="Pricing | PhotoInvoice" />

      <h1>Pricing</h1>

      <SubTitle>
        <Highlight>Pays for itself</Highlight> by eliminating hours of tedious
        invoice tracking & late payment reminders.
      </SubTitle>

      <EditionsSection />

      <CTASection
        title="See how it works, risk-free."
        subtitle="Experiment with a sample invoice and see how to get paid without the hassle."
        buttonText="Create an Account"
      />
    </Layout>
  );
};

export default PricingPage;

const SubTitle = styled.h3`
  color: #333;
  text-align: center;
  font-weight: 700;
  font-size: 21px;
  margin: 0 auto;
  max-width: 520px;
  line-height: 1.7;

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const Highlight = styled.span`
  background-color: #6cd4f1;
  padding: 2px 4px;
`;
