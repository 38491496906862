import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Layout from 'components/shared/Layout'

const NotFoundPage = () => (
  <Layout>
    <h1>Page Not Found</h1>
    <Content>
      <p>
        We&apos;re sorry, but the URL you entered doesn&apos;t match any of our
        pages!
      </p>
      <p>But while you&apos;re here, we recommend these helpful pages:</p>
      <LinkList>
        <li>
          <Link to="/">Home Page</Link>
        </li>
        <li>
          <Link to="/pricing">Pricing</Link>
        </li>
        <li>
          <Link to="/how-it-works">How It Works</Link>
        </li>
        <li>
          <Link to="/faqs">FAQs</Link>
        </li>
      </LinkList>
    </Content>
  </Layout>
)

export default NotFoundPage

const Content = styled.div`
  width: 700px;
  max-width: 96%;
  margin: 0 auto;
`

const LinkList = styled.ul`
  margin: 30px 50px 50px;

  li {
    margin: 10px 0;
    font-size: 21px;
  }
`
