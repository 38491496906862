import React, { Component } from 'react'
import styled from 'styled-components'

import { colors } from 'Constants'

class SignupButtonLarge extends Component {
  render() {
    return (
      <Link href="https://app.photoinvoice.com/signup">
        <SignupButton>
          <ButtonMain>Sign Up For Free</ButtonMain>
        </SignupButton>
      </Link>
    )
  }
}

SignupButtonLarge.propTypes = {}

export default SignupButtonLarge

const Link = styled.a`
  max-width: 100%;
`

const SignupButton = styled.button`
  display: inline-block;
  width: 340px;
  height: 100px;
  max-width: 90%;
  border-radius: 60px;
  background-color: ${colors.rose};
  padding: 24px;
  color: white;
  margin: 0 auto;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.4);
  transition: background-color 250ms, transform 250ms;

  &:hover {
    background-color: ${colors.roseHover};
  }

  &:active {
    transform: scale(0.99);
  }

  @media (max-width: 460px) {
    width: 340px;
    height: 80px;
  }
`

const ButtonMain = styled.div`
  font-weight: 900;
  font-size: 28px;

  @media (max-width: 460px) {
    font-size: 22px;
  }
`
