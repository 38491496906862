import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import Layout from "components/shared/Layout";
import fosterFamSrc from "assets/about/photo-invoice-foster-fam.jpg";
import DemoCTA from "components/shared/DemoCTA";
import { colors } from "Constants";

const AboutPage = () => {
  return (
    <Layout>
      <Helmet title="About | PhotoInvoice" />

      <Content>
        <h1>About</h1>

        <Intro>
          PhotoInvoice exists to help photographers run more efficient
          businesses.
        </Intro>

        <p>
          Our focus is building the quickest and easiest way for photographers
          to invoice their clients and get paid- including tools that make
          things more efficient, more streamlined, and less manual.
        </p>

        <p>
          The business originally came from my own frustrations from running a
          real estate photography business. I’m Mark- you’ve probably met me or
          emailed with me if you’ve been a part of the PhotoInvoice crew for
          very long:
        </p>

        <p>
          <Img src={fosterFamSrc} />
        </p>

        <p>
          I’m a (now former) real estate photographer, and even though I’m an
          organized and detail-oriented person, I had a lot of issues when it
          came to invoicing my clients. Part of it was I didn’t want to be{" "}
          <em>that guy</em> hounding them for money. The other part was just
          being busy… there’s always more you can do for marketing, learning,
          etc.
        </p>

        <p>
          I’m not sure where I first saw the idea, but someone mentioned
          collecting payment upfront. And to me, this was a light-bulb moment.
          Of course! Let the agent see a few samples up front, but collect
          payment before you let them download and use all your work.
        </p>

        <p>So I got to work.</p>

        <p>
          When PhotoInvoice was at the point where I could use it for my own
          business, the result was pretty eye-opening. For instance, we had one
          client who typically paid about a month late. She was one of our
          oldest clients, and I really liked her as a person, so I always bit
          the bullet and just waited a few weeks for payment.
        </p>

        <p>
          When we sent her the invoice with PhotoInvoice, she paid{" "}
          <em>within hours</em>. Not weeks, not days. <em>Hours</em>. I was
          instantly hooked, and began to get the system ready to show to other
          people.
        </p>

        <p>
          So that’s where we are now. Our goal is to make invoicing, payments,
          and photo delivery a completely optimized and pain free experience.{" "}
        </p>

        <p>
          Whether you’re currently a part of the PhotoInvoice crew or not, I’m
          always interested to hear from you about your business. Send me an
          email at{" "}
          <a href="mailto:mark@photoinvoice.com">mark@photoinvoice.com</a> and
          lets start a conversation 😄.
        </p>
      </Content>

      <DemoCTA />
    </Layout>
  );
};

export default AboutPage;

const Content = styled.div`
  width: 600px;
  font-size: 21px;
  line-height: 1.5;
  max-width: 90%;
  margin: 0 auto 80px;

  p {
    margin: 28px 0;

    @media (max-width: 640px) {
      font-size: 18px;
    }
  }
`;

const Intro = styled.div`
  background-color: ${colors.dkBlue};
  border-radius: 10px;
  color: white;
  padding: 20px;
  width: 500px;
  max-width: 100%;
  margin: 0 auto 40px;
  display: block;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
`;

const Img = styled.img`
  width: 400px;
  max-width: 100%;
  display: block;
  margin: 60px auto;
  border-radius: 10px;
`;
