import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import FAQItem from "./FAQItem";

function FAQList() {
  return (
    <Wrapper>
      <FAQItem title="How much does it cost?">
        <p>
          With PhotoInvoice you can choose between per-invoice pricing or a
          monthly subscription.
        </p>
        <p>
          Full pricing details can be found on our{" "}
          <Link to="/pricing">pricing page</Link>.
        </p>
      </FAQItem>

      <FAQItem title="How long does it take for funds to reach my bank account?">
        <p>
          Funds are deposited into your bank account two business days after an
          invoice has been paid.
        </p>
        <p>
          Note: As an anti-fraud measure, your first payout takes longer than
          normal, up to 7-10 days.
        </p>
        <p>
          For more details, see Stripe&apos;s documentation about{" "}
          <a
            href="https://stripe.com/docs/payouts"
            target="_blank"
            rel="noopener noreferrer"
          >
            receiving payouts
          </a>
          .
        </p>
      </FAQItem>

      <FAQItem title="Can photos be released before payment for trusted clients?">
        <p>
          Yes, every invoice has the option to release assets regardless of
          payment, or once the invoice has been paid.
        </p>
      </FAQItem>

      <FAQItem title="Does it handle sales tax?">
        <p>
          Yes, you can configure your invoices to automatically include a
          certain percentage as tax, override this value per-invoice, and
          specify the label used (&quot;Sales Tax&quot;, &quot;VAT&quot;, etc).
        </p>
      </FAQItem>

      <FAQItem title="Can I connect PhotoInvoice with a payment provider other than Stripe?">
        <p>
          At this time Stripe is our only payment provider option. Stripe is
          easy to set up, has the same fees as other providers (Square, PayPal,
          etc), and offers a great user experience.
        </p>
      </FAQItem>

      <FAQItem title="What currencies are available?">
        <p>
          PhotoInvoice can be configured to accept US Dollars, Australian
          Dollars, Canadian Dollars, British Pounds, or Euros.
        </p>
      </FAQItem>

      <FAQItem title="Can I generate quarterly reports for tax reporting, etc?">
        <p>
          Yes, we offer invoice and revenue reports that should allow you to
          pull whatever data needed. We are also happy to help create custom
          reports, just <a href="mailto:info@photoinvoice.com">contact us</a>.
        </p>
      </FAQItem>

      <FAQItem title="How do sample images and watermarks work?">
        <p>
          Sample images are displayed on the unpaid invoice in order to give
          your client a preview of the shoot. PhotoInvoice automatically
          watermarks sample images when they are uploaded.
        </p>
        <p>
          By default, samples are watermarked with the word{" "}
          <strong>SAMPLE</strong>. On the Professional Edition of PhotoInvoice,
          samples can be watermarked with your custom logo or graphic to further
          personalize the payment experience.
        </p>
      </FAQItem>
    </Wrapper>
  );
}

export default FAQList;

const Wrapper = styled.ul`
  width: 700px;
  max-width: 96%;
  margin: 30px auto;
  list-style: none;
`;
