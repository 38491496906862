import React, { Component } from "react";
import { Link as LinkStarter } from "gatsby";
import styled from "styled-components";

import { PrimaryButton } from "components/shared/Buttons/Buttons2";
import { colors } from "Constants";
import logoSrc from "assets/logo.svg";
import menuOpenSrc from "assets/menu-open.svg";
import menuCloseSrc from "assets/menu-close.svg";

class Nav extends Component {
  state = { showMenu: false };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  render() {
    return (
      <HeaderWrapper>
        <HeaderContent>
          <LogoWrapper>
            <LogoLink to="/">
              <Logo src={logoSrc} />
            </LogoLink>
          </LogoWrapper>

          <LinksWrapper mobileShow={this.state.showMenu}>
            <NavItem>
              <Link
                to="/"
                activeStyle={{ color: colors.accentDark }}
                onClick={this.closeMenu}
              >
                Home
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/how-it-works"
                activeStyle={{ color: colors.accentDark }}
                onClick={this.closeMenu}
              >
                How It Works
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="/pricing"
                activeStyle={{ color: colors.accentDark }}
                onClick={this.closeMenu}
              >
                Pricing
              </Link>
            </NavItem>

            <NavItem>
              <Link
                to="/faqs"
                activeStyle={{ color: colors.accentDark }}
                onClick={this.closeMenu}
              >
                FAQs
              </Link>
            </NavItem>

            <NavItem>
              <ExtLink href="https://app.photoinvoice.com">Sign In</ExtLink>
            </NavItem>
            <NavItem>
              <ExtLink href="https://app.photoinvoice.com/signup">
                <ActionButton>Get Started</ActionButton>
              </ExtLink>
            </NavItem>
          </LinksWrapper>
          <MobileMenuBtn onClick={this.toggleMenu}>
            {this.state.showMenu ? (
              <MenuIcon open src={menuCloseSrc} />
            ) : (
              <MenuIcon src={menuOpenSrc} />
            )}
          </MobileMenuBtn>
        </HeaderContent>
      </HeaderWrapper>
    );
  }
}

export default Nav;

const MENU_BREAK = "960px";
const MENU_VERTICAL_BREAK = "640px";

const HeaderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #efefef;
`;

const HeaderContent = styled.div`
  position: relative;
  width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled(LinkStarter)`
  font-weight: 400;
  color: ${colors.raisin};
  margin: 0 14px;
  font-size: 16px;
  transition: color 250ms;

  &:hover {
    color: ${colors.accentDark};
  }

  @media (max-width: 1100px) {
    font-size: 15px;
    margin: 0 8px;
  }

  @media (max-width: 370px) {
    font-size: 16px;
    margin: 0 10px;
  }
`;

const LogoWrapper = styled.div``;

const LogoLink = styled(Link)`
  margin: 0;
`;

const Logo = styled.img`
  width: 340px;
  max-width: 92%;
`;

const LinksWrapper = styled.nav`
  z-index: 1000;

  @media (max-width: ${MENU_BREAK}) {
    display: ${(props) => (props.mobileShow ? "flex" : "none")};
    position: absolute;
    top: 48px;
    right: 0;
    background-color: white;
    border-bottom: 1px solid #efefef;
    width: 100%;
    height: 70px;
    justify-content: space-around;
    align-items: center;
  }

  @media (max-width: ${MENU_VERTICAL_BREAK}) {
    flex-direction: column;
    height: auto;
    padding-bottom: 24px;
  }
`;

const NavItem = styled.div`
  display: inline-block;
  white-space: nowrap;
  color: ${colors.raisin};

  @media (max-width: ${MENU_VERTICAL_BREAK}) {
    line-height: 44px;
  }
`;

const MobileMenuBtn = styled.div`
  display: none;

  @media (max-width: ${MENU_BREAK}) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    color: ${colors.raisin};
  }
`;

const MenuIcon = styled.img`
  width: ${(props) => (props.open ? "24px" : "28px")};
`;

const ExtLink = styled.a`
  font-weight: 400;
  color: ${colors.raisin};
  margin: 0 14px;
  font-size: 16px;
  transition: color 250ms;

  &:hover {
    color: ${colors.accentDark};
  }

  @media (max-width: 1100px) {
    font-size: 15px;
    margin: 0 8px;
  }

  @media (max-width: 370px) {
    font-size: 16px;
  }
`;

const ActionButton = styled(PrimaryButton)`
  font-size: 17px;
  padding: 0 24px;

  @media (max-width: ${MENU_VERTICAL_BREAK}) {
    margin-top: 10px;
  }
`;
