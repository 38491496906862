import React from "react";
import styled from "styled-components";

import CustomerQuote from "components/shared/CustomerQuote";
import customerLogos from "assets/home/customer-logos.jpg";

import mlsCameraGuyLogo from "assets/home/logo-mls-camera-guy.jpg";
import newListingMediaLogo from "assets/home/logo-new-listing-media.jpg";

function SocialProof() {
  return (
    <Wrapper>
      <Content>
        <FeaturedSection>
          <CustomerQuote
            author="Art @ MLS Camera Guy"
            quote="I finally found the perfect way to collect payment for my
            photos! I looked at several alternatives, but none were as
            spot-on as PhotoInvoice."
            logo={mlsCameraGuyLogo}
          />

          <CustomerQuote
            author="David @ New Listing Media"
            quote="If you are looking for a new delivery system and invoicing
            with pay to download, check out PhotoInvoice. We just
            transitioned and it is going really well!"
            logo={newListingMediaLogo}
          />
        </FeaturedSection>

        <Logos src={customerLogos} />
      </Content>
    </Wrapper>
  );
}

export default SocialProof;

const Wrapper = styled.div`
  background-color: white;
`;

const Content = styled.div`
  width: 1400px;
  max-width: 100%;
  margin: 0 auto;
  padding: 50px 0 0;
`;

const FeaturedSection = styled.div`
  display: flex;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Logos = styled.img`
  max-width: 100%;
  margin: 20px auto 0;
`;
