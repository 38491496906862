import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

CustomerQuote.propTypes = {
  author: PropTypes.string,
  logo: PropTypes.string,
  quote: PropTypes.string
};

export default function CustomerQuote({ author, logo, quote }) {
  return (
    <Wrapper>
      <Logo>
        <img src={logo} />
      </Logo>
      <Quote>
        <Text>&quot;{quote}&quot;</Text>
        <Author>- {author}</Author>
      </Quote>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  margin: 0 30px;
  max-width: 700px;

  @media (max-width: 1000px) {
    margin: 10px 30px;
  }

  @media (max-width: 600px) {
    margin: 10px;
  }
`;

const Logo = styled.div`
  position: absolute;
  left: 0px;
  flex-shrink: 0;
  background-color: white;
  border-radius: 100%;
  height: 150px;
  width: 150px;

  box-shadow: 0 0 80px -22px rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 120px;

    @media (max-width: 600px) {
      width: 80px;
    }
  }

  @media (max-width: 600px) {
    height: 100px;
    width: 100px;
  }
`;

const Quote = styled.div`
  background: #d5ebff;
  margin: 10px 10px 10px 30px;
  padding: 20px 20px 20px 145px;
  border-radius: 20px;
  color: #374b5e;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 600px) {
    padding: 20px 20px 20px 90px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 1.4;

  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Author = styled.div`
  margin-top: 10px;
  font-size: 15px;
  opacity: 0.7;
  font-style: italic;

  @media (max-width: 600px) {
    font-size: 13px;
  }
`;
