import React, { Component } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import styled from "styled-components";

import PricingFeatureList from "./_PricingFeatureList";
import { colors } from "Constants";

Edition.propTypes = {
  title: PropTypes.string,
  isMonthly: PropTypes.bool,
  price: PropTypes.string,
  text: PropTypes.string,
  lineColor: PropTypes.string,
  featured: PropTypes.bool,
  showAnnual: PropTypes.bool,
  annualText: PropTypes.string,
};

function Edition({
  title,
  isMonthly,
  price,
  text,
  lineColor,
  featured,
  showAnnual,
  annualText,
}) {
  return (
    <Wrapper>
      <Box featured={featured}>
        <TitleWrapper>
          <Title>{title}</Title>
          <Divider color={lineColor} />
        </TitleWrapper>

        <PriceWrapper>
          <Price
            featured={featured}
            key={price}
            initial={{ opacity: 0, rotateY: -180 }}
            animate={{ opacity: 1, rotateY: 0 }}
            transition={{ duration: 0.3 }}
          >
            {price}
          </Price>
          <SubPrice>{isMonthly ? "per month" : "per invoice"}</SubPrice>
        </PriceWrapper>

        {showAnnual ? (
          <Annual
            key={price}
            initial={{ opacity: 0, y: -30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <AnnualBannerOuter>
              <AnnualBanner>2 months free</AnnualBanner>
            </AnnualBannerOuter>
            <AnnualText>{annualText}</AnnualText>
          </Annual>
        ) : null}

        <Text>{text}</Text>
      </Box>
      <PricingFeatureList edition={title} />
    </Wrapper>
  );
}

export default Edition;

const Wrapper = styled.div`
  width: 280px;

  @media (max-width: 650px) {
    width: 90%;
    margin: 0 auto;
  }
`;

const Box = styled.div`
  border-radius: 20px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 10px 20px;
  text-align: center;
  color: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 320px;
  transform: ${(props) => (props.featured ? "scale(1.1)" : null)};
  background-color: ${(props) => (props.featured ? "#F0FCFF" : "white")};
`;

const TitleWrapper = styled.div``;

const Title = styled.h3`
  text-transform: uppercase;
  font-weight: 400;
  color: ${colors.primary};
  margin-bottom: 15px;
`;

const Divider = styled.div`
  width: 75px;
  margin: 0 auto;
  height: 5px;
  background-color: ${(props) => props.color};
`;

const PriceWrapper = styled.div``;

const Price = styled(motion.div)`
  color: #333;
  margin-top: 15px;
  font-weight: 900;
  font-size: ${(props) => (props.featured ? "64px" : "58px")};

  @media (max-width: 750px) {
    font-size: ${(props) => (props.featured ? "54px" : "48px")};
  }
`;

const SubPrice = styled.div`
  font-size: 14px;
  font-weight: 700;
  opacity: 0.7;
`;

const Text = styled.p`
  font-size: 12px;
  color: ${colors.raisin};
  opacity: 0.7;
  margin: 20px auto 10px;
  max-width: 200px;
  line-height: 1.3;
`;

const Annual = styled(motion.div)`
  margin-top: 15px;
`;

const AnnualBannerOuter = styled.div``;

const AnnualBanner = styled.div`
  display: inline-block;
  width: auto;
  font-size: 13px;
  font-weight: 700;
  background: ${colors.primary};
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
`;

const AnnualText = styled.div`
  margin-top: 5px;
  font-size: 13px;
  color: ${colors.primary};
`;
