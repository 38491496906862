import React, { useState } from "react";
import styled from "styled-components";
import Switch from "react-switch";

import Edition from "./_Edition";
import { colors } from "Constants";

function EditionsSection() {
  const [showMonthly, setShowMonthly] = useState(false);

  const toggleShowMonthly = () => {
    setShowMonthly(!showMonthly);
  };

  return (
    <Wrapper>
      <Annual>
        <AnnualLabel onClick={() => setShowMonthly(false)}>
          Pay Annually
          <SubAnnual>2 months free!</SubAnnual>
        </AnnualLabel>
        <SwitchWrapper>
          <Switch
            checked={showMonthly}
            onChange={toggleShowMonthly}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor={colors.accent}
            offColor={colors.accentGreen}
            handleDiameter={0}
          />
        </SwitchWrapper>
        <MonthlyLabel onClick={() => setShowMonthly(true)}>
          Pay Monthly
        </MonthlyLabel>
      </Annual>

      <EditionsWrapper>
        <Edition
          title="Starter"
          isMonthly={false}
          price="2%"
          text="Level up your invoicing, without a monthly subscription."
          lineColor="#2BC4A0"
          featured={false}
        />
        <Edition
          title="Standard"
          isMonthly={true}
          price={showMonthly ? "$29" : "$24"}
          text="Never get paid late again, with a flat monthly fee."
          lineColor="#01BCF0"
          featured={true}
          showAnnual={!showMonthly}
          annualText="$290 billed annually"
        />
        <Edition
          title="Professional"
          isMonthly={true}
          price={showMonthly ? "$49" : "$41"}
          text="Greater customization and asset delivery options for effective photography businesses."
          lineColor="#7411CC"
          featured={false}
          showAnnual={!showMonthly}
          annualText="$490 billed annually"
        />
      </EditionsWrapper>

      <CardProcessing>
        *Note: Regardless of plan, invoice payments are subject to Stripe's
        standard card processing fee of 2.9% + 30¢.
      </CardProcessing>
    </Wrapper>
  );
}

export default EditionsSection;

const Wrapper = styled.div``;

const Annual = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SwitchWrapper = styled.div`
  margin: 0 10px;
`;

const SwitchLabel = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #595959;
  cursor: pointer;
`;

const MonthlyLabel = styled(SwitchLabel)`
  margin-top: -7px;
`;

const AnnualLabel = styled(SwitchLabel)`
  margin-top: 7px;
`;

const SubAnnual = styled.div`
  font-size: 12px;
  margin-top: 2px;
  font-weight: 400;
  text-align: center;
`;

const EditionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1200px;
  width: 96%;
  margin: 40px auto 10px;

  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const CardProcessing = styled.div`
  text-align: center;
  color: #5e5e5e;
  font-size: 15px;
  font-weight: 400;
  margin-top: 40px;
  margin-bottom: 40px;
`;
