import React from "react";
import Helmet from "react-helmet";

import Layout from "components/shared/Layout";
import FAQList from "./FAQList";
import CTASection from "../shared/CTASection";

const FAQPage = () => {
  return (
    <Layout>
      <Helmet title="FAQs | PhotoInvoice" />

      <h1>FAQs</h1>

      <FAQList />

      <CTASection
        title="Create your first hassle-free invoice"
        subtitle="Test your first invoice in seconds, for free."
      />
    </Layout>
  );
};

export default FAQPage;
