import React from "react";
import Helmet from "react-helmet";

import Layout from "components/shared/Layout";

import HIWTopSection from "components/HowItWorks/TopSection";
import SingleItem from "components/HowItWorks/SingleItem";

import createInvoiceSrc from "assets/how-it-works/create-invoice.jpg";
import deliverablesSrc from "assets/how-it-works/invoice-deliverables.png";
import emailSrc from "assets/how-it-works/sample-email.png";
import paidInvoiceSrc from "assets/how-it-works/unpaid-to-paid-invoice.png";

import CTASection from "components/shared/CTASection";

const HowItWorksPage = () => {
  return (
    <Layout>
      <Helmet title="How It Works | PhotoInvoice" />

      <HIWTopSection />

      <SingleItem
        title="1: Create an Invoice"
        description="When you’re ready to invoice a client, it takes just a few
          seconds to select the customer and enter line items and prices."
        imgSrc={createInvoiceSrc}
      />

      <SingleItem
        title="2: Add Samples and Deliverables"
        description="Sample photos will be automatically watermarked and shown on the unpaid invoice. Deliverables are items the client can access only after payment. You can share links (i.e. shared Dropbox/Drive folders or virtual tours) or deliver photos directly with the Professional Edition."
        imgSrc={deliverablesSrc}
      />

      <SingleItem
        title="3: Send the Invoice"
        description={`You’re now ready to send the invoice to your client. We make this a breeze with custom email message templates and dynamic placeholder options.`}
        imgSrc={emailSrc}
      />

      <SingleItem
        title="4: Get Paid"
        description={`When your client visits the invoice link, they can view any sample images you provide, and are prompted to submit payment in order to access the deliverables. Once payment has been made, they can instantly access the links and photos you’ve included.`}
        imgSrc={paidInvoiceSrc}
        imgStylesDisabled={true}
      />

      <CTASection
        title="See for yourself, risk&#8209;free."
        subtitle="Experiment with a sample invoice and see how to get paid hassle-free."
        buttonText="Create an Account"
      />
    </Layout>
  );
};

export default HowItWorksPage;
