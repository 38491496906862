import React from "react";
import styled from "styled-components";

import { colors } from "Constants";

export default function PaidServiceCallout() {
  return (
    <Wrapper>
      <p>
        Don&apos;t want to spend time fine-tuning your watermark? Let us do the
        work. For a one-time fee of $99, we&apos;ll help transform your logo
        into the perfect custom watermark for your PhotoInvoice samples. Email{" "}
        <a href="mailto:support@photoinvoice.com">support@photoinvoice.com</a>{" "}
        to get started.
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${colors.ltPrimaryBackground};
  border-left: 4px solid ${colors.accent};
  /* padding-left: 16px; */
  padding: 10px 20px;
  border-radius: 0 20px 20px 0;

  p {
    color: ${colors.primary};
    margin-bottom: 1em;
  }
`;
