import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components/macro";

import Layout from "components/shared/Layout";
import {
  Wrapper,
  Title,
  Content,
  ImgCentered,
  Divider,
} from "styles/PageStyles";

import keyboardShortcutsImg from "./keyboard-shortcuts.png";

export default function InvoiceDescription() {
  return (
    <Layout>
      <Helmet title="Keyboard Shortcuts | PhotoInvoice" />
      <Wrapper>
        <Title>Keyboard Shortcuts</Title>
        <Content>
          <p>
            Keyboard shortcuts are a cornerstone of saving time while performing
            repetitive tasks on a computer... and at PhotoInvoice we{" "}
            <em>love</em> helping photographers save time.
          </p>

          <p>
            You'll find keyboard shortcuts throughout the invoicing process.
            When possible, we include a shortkey helper right on the button or
            action it is related to:
          </p>

          <ImgCentered
            src={keyboardShortcutsImg}
            alt="PhotoInvoice keyboard shortcuts"
          />

          <p>
            Here is a full list of the shortcuts we have available. If we're
            missing one you'd like to see,{" "}
            <a
              href="mailto:support@photoinvoice.com"
              target="_blank"
              rel="noreferrer"
            >
              let us know
            </a>
            !
          </p>

          <Divider />

          <h3>Shortcuts while editing an invoice</h3>
          <Table>
            <thead>
              <tr>
                <td>Page/Area</td>
                <td>Action</td>
                <td>Shortkey</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Invoice</td>
                <td>Add Line Item</td>
                <td>I</td>
              </tr>
              <tr>
                <td>Invoice</td>
                <td>Add Discount</td>
                <td>D</td>
              </tr>
              <tr>
                <td>Invoice</td>
                <td>Add Templates</td>
                <td>T</td>
              </tr>
              <tr>
                <td>Invoice - Deliverables</td>
                <td>Add Link Deliverable</td>
                <td>L</td>
              </tr>
              <tr>
                <td>Invoice - Deliverables</td>
                <td>Add Text Deliverable</td>
                <td>T</td>
              </tr>
              <tr>
                <td>Invoice - Deliverables</td>
                <td>Add Photos Deliverable</td>
                <td>P</td>
              </tr>
              <tr>
                <td>Invoice - Deliverables</td>
                <td>Add Deliverable Templates</td>
                <td>T</td>
              </tr>

              <tr>
                <td>Invoice</td>
                <td>Open "Send Invoice" modal</td>
                <td>S</td>
              </tr>
              <tr>
                <td>Invoice - Send Modal</td>
                <td>Copy invoice URL</td>
                <td>cmd-C</td>
              </tr>
              <tr>
                <td>Invoice</td>
                <td>View Payable Invoice</td>
                <td>V</td>
              </tr>
              <tr>
                <td>Invoice</td>
                <td>Delete Invoice</td>
                <td>cmd-Delete</td>
              </tr>
            </tbody>
          </Table>

          <h3>Other shortcuts</h3>
          <Table>
            <thead>
              <tr>
                <td>Page/Area</td>
                <td>Action</td>
                <td>Shortkey</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Invoices Page</td>
                <td>Create a new invoice</td>
                <td>N</td>
              </tr>
              <tr>
                <td>Clients Page</td>
                <td>Create a new client</td>
                <td>N</td>
              </tr>
            </tbody>
          </Table>
        </Content>
      </Wrapper>
    </Layout>
  );
}

const Table = styled.table`
  width: 100%;

  border-collapse: collapse;

  thead {
    font-weight: 600;
  }

  tbody {
    tr {
      border-top: 1px solid #ccc;
    }
    td {
      padding: 2px 0;
      font-size: 16px;
    }
  }
`;
