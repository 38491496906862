import React, { useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronRight } from "react-feather";

import { colors } from "Constants";

FAQItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

function FAQItem({ children, title }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <Wrapper onClick={() => setExpanded(!expanded)}>
      <Button>
        <IconWrapper animate={{ rotate: expanded ? "90deg" : 0 }}>
          <ChevronRight color={colors.accent} />
        </IconWrapper>
        <Title>{title}</Title>
      </Button>
      <Content animate={{ height: expanded ? "auto" : 0 }}>
        {children}
        <BottomSpacer />
      </Content>
    </Wrapper>
  );
}

export default FAQItem;

const Wrapper = styled.li`
  background-color: #efefef;
  border-radius: 10px;
  margin-bottom: 10px;
`;

const Button = styled.button`
  cursor: pointer;
  background: transparent;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
`;

const IconWrapper = styled(motion.div)`
  display: inline-block;
  width: 45px;
`;

const Title = styled.h3`
  color: ${colors.primary};
  font-weight: 400;
  font-size: 18px;
  flex: 1;
  text-align: left;
`;

const Content = styled(motion.div)`
  height: 0;
  margin-left: 45px;
  margin-right: 20px;
  color: #363636;
  line-height: 1.3;
  font-size: 16px;
  overflow: hidden;
`;

const BottomSpacer = styled.div`
  height: 20px;
`;
