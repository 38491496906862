import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Feature from "./_Feature";

// Feature Images
import getPaidFastImg from "assets/home/feature-get-paid-fast.png";
import invoicesPaid from "assets/home/feature-invoices-paid.png";
import noReminders from "assets/home/feature-no-reminders.png";
import flexibleDeliveryImg from "assets/home/feature-flexible-delivery.jpg";
import everythingImg from "assets/home/feature-everything.jpg";

function FeaturesSection() {
  return (
    <Wrapper>
      <Feature
        direction="right"
        title="Flexible Delivery"
        image={flexibleDeliveryImg}
      >
        <p>
          Deliver photos, links, videos, and files directly with our
          Professional Edition, or with any service that provides a shareable
          link (Dropbox, Drive, WeTransfer, etc).
        </p>
        <p>
          Want to give a trusted client access to the finals before payment?
          Each invoice can be set to deliver before or after payment.
        </p>
      </Feature>

      <Feature
        direction="left"
        title="Release-Upon-Payment"
        image={getPaidFastImg}
      >
        <p>No more waiting around for overdue invoices.</p>
        <p>
          Your clients see watermarked samples and they want the finals. So
          instead of putting it off, they pay. <em>Fast</em>.
        </p>
        <p>Suddenly your chronically late clients are paying immediately.</p>
      </Feature>

      <Feature
        direction="right"
        title="Invoicing on Autopilot"
        image={invoicesPaid}
      >
        <p>
          No need to worry about something slipping through the cracks, or
          meticulously track every invoice.
        </p>
        <p>
          When finals are only released upon payment, everything gets paid,
          without needing constant attention.
        </p>
      </Feature>

      <Feature
        direction="left"
        title="Eliminate Reminders and Followups"
        image={noReminders}
      >
        <p>
          You&apos;ll never have to send another annoying payment reminder or
          invoice followup.
        </p>
        <p>
          Since payment was required upfront and the assets have already been
          delivered, everyone’s happy… no followups required.
        </p>
      </Feature>

      <Feature direction="right" title="Plus Even More..." image={null}>
        <ul style={{ marginLeft: 40 }}>
          <li>Accept tips</li>
          <li>Automatic sales tax</li>
          <li>Currency options</li>
          <li>Reports</li>
          <li>Custom image watermarks</li>
          <li>Line item &amp; deliverable templates</li>
          <li>Deliver photos directly (without Dropbox, etc)</li>
          <li>Custom email templates</li>
          <li>
            <Link to="/how-it-works">And more...</Link>
          </li>
        </ul>
      </Feature>
    </Wrapper>
  );
}

export default FeaturesSection;

const Wrapper = styled.div``;
