import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";

import "stylesheets/normalize.css";
import "stylesheets/styles.css";

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        title="PhotoInvoice"
        meta={[
          {
            name: "description",
            content:
              "Photography Invoices That Get Paid Immediately, With Zero Hassle",
          },
          {
            name: "keywords",
            content: "photography, invoice, invoicing, upfront, real estate",
          },
        ]}
      />
      <Wrapper>
        <Nav />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Layout;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  flex: 1;
`;
