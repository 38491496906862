import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";

import customDomainImg from "assets/features/custom-domain.png";
import Layout from "components/shared/Layout";
import CTASection from "components/shared/CTASection";

export default function CustomDomainPage() {
  return (
    <Layout>
      <Content>
        <Helmet title="Custom Domain | PhotoInvoice" />
        <h1>Use Your Own Custom Domain</h1>

        <img src={customDomainImg} alt="PhotoInvoice custom domain feature" />

        <p>
          Integrating PhotoInvoice with your own custom domain allows you to
          create and send real estate photography invoices that appear to be
          served on the same domain as your website.
        </p>
        <Spacer />
        <p>
          For instance, if your website/domain is:
          <div>
            <strong>www.realestatephotos.com</strong>
          </div>
        </p>
        <p>
          Your PhotoInvoice invoices could be configured to display at:
          <div>
            <strong>invoice.realestatephotos.com</strong>
          </div>
        </p>
        <Spacer />
        <p>
          This keeps clients on your website and in your ecosystem, and gives a
          cohesive and professional payment experience that your clients will
          love.
        </p>

        <p>
          Custom domain setup is available on all edition levels for a one-time
          setup fee of $99 (domain not included).
        </p>

        <Spacer />

        <p>
          To get started, head over to your{" "}
          <a href="https://app.photoinvoice.com/settings/custom-domain">
            PhotoInvoice settings
          </a>
          .
        </p>

        <p>
          Questions? We&apos;re happy to help!{" "}
          <a href="mailto:support@photoinvoice.com">support@photoinvoice.com</a>
        </p>
      </Content>

      <CTASection
        title="Create your first hassle-free invoice"
        subtitle="Test your first invoice in seconds, for free."
      />
    </Layout>
  );
}

const Content = styled.div`
  box-sizing: border-box;
  width: 700px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;

  p {
    line-height: 1.7;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
  }
`;

const Spacer = styled.div`
  height: 5px;
`;
