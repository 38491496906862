import React from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { Link } from "gatsby";

import Layout from "components/shared/Layout";
import articleData from "./grow-article-data.json";
import { colors } from "Constants";
import headerImg from "assets/grow-your-business/real-estate-photography-grow-business-header.jpg";
import Tags from "components/GrowYourBusiness/Tags";

const GrowYourBusinessPage = () => {
  return (
    <Layout>
      <Helmet title="Grow Your Business | PhotoInvoice" />

      <TitleImg>
        <Title>Grow Your Business</Title>
      </TitleImg>

      <Content>
        <Lead>
          {`Want to book more shoots? Charge more? Operate more efficiently? Let us help you get there with articles to
            level up your photography business.`}
        </Lead>

        <Divider />

        {articleData.map(article => {
          return (
            <Link to={`/grow-your-business/${article.slug}`} key={article.slug}>
              <ArticleWrapper>
                <Tags tags={article.tags} />
                <PostTitle>{article.title}</PostTitle>
              </ArticleWrapper>
            </Link>
          );
        })}
      </Content>
    </Layout>
  );
};

export default GrowYourBusinessPage;

const TitleImg = styled.div`
  background-image: linear-gradient(
      rgba(28, 37, 65, 0.6),
      rgba(28, 37, 65, 0.6)
    ),
    url(${headerImg});
  width: 800px;
  max-width: 100%;
  height: 240px;
  margin: 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    height: 180px;
  }
`;

const Title = styled.h1`
  color: white;
  padding: 20px;

  @media (max-width: 450px) {
    font-size: 36px;
  }
`;

const Content = styled.div`
  width: 800px;
  max-width: 96%;
  margin: 0 auto 30px;
`;

const Lead = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
  font-size: 21px;

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #9e9e9e;
  width: 250px;
  margin: 50px auto 40px;
`;

const ArticleWrapper = styled.div`
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  transition: background-color 250ms;

  &:hover {
    background-color: ${colors.ltPrimaryBackground};
  }
`;

const PostTitle = styled.h3`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;
