import React, { Component } from "react";
import styled from "styled-components";
import { Link as LinkStarter } from "gatsby";

import { colors } from "Constants";
import logoSrc from "assets/logo-white.svg";

class Footer extends Component {
  render() {
    const year = new Date().getFullYear();

    return (
      <Wrapper>
        <Line />
        <Content>
          <LogoColumn>
            <Link to="/">
              <Logo src={logoSrc} />
            </Link>
          </LogoColumn>
          <LinkColumns>
            <LinkColumn>
              <ColumnTitle>Learn</ColumnTitle>
              <LinkItem>
                <Link to="/">Home</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/how-it-works">How It Works</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/pricing">Pricing</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/faqs">FAQs</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/features">Features</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/grow-your-business/">Grow Your Business</Link>
              </LinkItem>
              <LinkItem>
                <Link to="/about">About</Link>
              </LinkItem>
            </LinkColumn>

            <LinkColumn>
              <ColumnTitle>Get Started</ColumnTitle>
              <LinkItem>
                <a href="https://app.photoinvoice.com/signup">Sign Up</a>
              </LinkItem>
              <LinkItem>
                <a href="https://app.photoinvoice.com/">Sign In</a>
              </LinkItem>
            </LinkColumn>

            <LinkColumn>
              <ColumnTitle>Contact</ColumnTitle>
              <LinkItem>
                <a href="mailto:info@photoinvoice.com">info@photoinvoice.com</a>
              </LinkItem>
            </LinkColumn>
          </LinkColumns>
        </Content>

        <Copyright>© {year} PhotoInvoice</Copyright>
      </Wrapper>
    );
  }
}

export default Footer;

const CONTENT_MAX_WIDTH = "1200px";

const Wrapper = styled.footer`
  background-color: ${colors.smokey};
`;

const Line = styled.div`
  width: 500px;
  max-width: 80%;
  margin: 0 auto;
  border-top: 1px solid #090808;
`;

const Content = styled.div`
  width: ${CONTENT_MAX_WIDTH};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LogoColumn = styled.div`
  @media (max-width: 800px) {
    margin-bottom: 10px;
  }
`;

const Logo = styled.img`
  width: 300px;
  max-width: 100%;

  @media (max-width: 1000px) {
    width: 240px;
  }

  @media (max-width: 800px) {
    width: 300px;
  }
`;

const LinkColumns = styled.div`
  display: flex;

  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LinkColumn = styled.ul`
  margin: 0 40px;
  list-style: none;

  li {
    margin: 14px 0;
  }

  @media (max-width: 1000px) {
    margin: 0 20px;
  }
`;

const ColumnTitle = styled.li`
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  white-space: nowrap;

  @media (max-width: 1000px) {
    font-size: 18px;
  }
`;

const LinkItem = styled.li`
  a {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
    transition: color 250ms;

    &:hover {
      color: white;
    }
  }
`;

const Link = styled(LinkStarter)``;

const Copyright = styled.div`
  text-align: center;
  font-size: 15px;
  color: white;
  opacity: 0.3;
  padding-bottom: 20px;
`;
