import styled from 'styled-components'
import { colors } from 'Constants'

export const RoundedButton = styled.button`
  display: inline-block;
  border-radius: 30px;
  padding: 0 30px;
  font-size: 20px;
  font-weight: 700;
  height: 54px;
  line-height: 54px;
  white-space: nowrap;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
`

export const RoundedButtonPrimary = styled(RoundedButton)`
  color: white;
  background-color: ${colors.rose};
  transition: background-color 250ms;

  &:hover {
    background-color: ${colors.roseHover};
  }
`

export const RoundedButtonOutline = styled(RoundedButton)`
  border: 1px solid ${colors.rose};
  color: ${colors.rose};
  transition: background-color 250ms, color 250ms;

  &:hover {
    background-color: ${colors.rose};
    color: white;
  }
`
