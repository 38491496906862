import requireUpfrontPayment from "assets/features/features-list/require-upfront-payment.jpg";
import watermarkedSamplesImg from "assets/features/features-list/watermarked-samples.jpg";
import automaticDeliveryImg from "assets/features/features-list/automatic-delivery.jpg";
import flexiblePhotoDelivery from "assets/features/features-list/flexible-photo-delivery.jpg";
import quickAddImg from "assets/features/features-list/quick-add.jpg";
import salesTaxImg from "assets/features/features-list/sales-tax.jpg";
import customInvoiceMsg from "assets/features/features-list/custom-invoice-messages.jpg";
import emailTemplate from "assets/features/features-list/email-template.jpg";
import tipping from "assets/features/features-list/tipping.jpg";
import customDomainImg from "assets/features/features-list/custom-domain.jpg";
import customWatermarkImg from "assets/features/features-list/custom-watermark.jpg";
import invoiceViewImg from "assets/features/features-list/invoice-view-history.png";

// {
//   title: '',
//   image: null,
//   text: '',
//   learnMoreLink: '',
//   tutorialLink: ''
// },

export const featuresData = [
  {
    title: "Require payment upfront",
    image: requireUpfrontPayment,
    text: "Say goodbye to waiting for weeks (or months) to get paid. Require payment in order to download the finals and get paid immediately.<br /><br />Have certain clients you want to exempt? No problem, set this feature on a per-invoice basis and let your best customers pay at their convenience.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Automatic delivery-on-payment",
    image: automaticDeliveryImg,
    text: "Clients shouldn’t have to wait for manual emails before getting the photos they&apos;ve paid for. <br /><br /> With PhotoInvoice, they get instant access to their photos upon payment... even if they pay while you&apos;re out on another shoot (or taking a nap).",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Watermarked sample photos",
    image: watermarkedSamplesImg,
    text: "If you want to give clients extra confidence before payment, upload a few (or all) of the photos. We’ll apply a watermark and display them on the unpaid invoice.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Flexible photo delivery",
    image: flexiblePhotoDelivery,
    text: "Deliver photos with whatever tool you prefer. PhotoInvoice is compatible with Dropbox, Google Drive, WeTransfer, and any other system that generates unique links for sharing. <br /><br />With the Professional Edition, you can even transfer photos directly, skipping the third party tools altogether.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Line item & deliverable templates",
    image: quickAddImg,
    text: "Create templates for your most commonly used line items and deliverables, and add them to your invoices with a single click.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Automatic sales tax",
    image: salesTaxImg,
    text: "If you need to collect sales tax or VAT, PhotoInvoice makes it automatic to collect the necessary percentage. Set a default percent value, and also override it on a per-invoice basis when needed.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Use your own custom domain",
    image: customDomainImg,
    text: "Send out invoice links that include your personal domain, giving your clients a more integrated payment experience with your brand.",
    learnMoreLink: "/features/custom-domain",
    tutorialLink: "",
  },
  {
    title: "Custom image watermarks",
    image: customWatermarkImg,
    text: "With the Professional Edition of PhotoInvoice, sample images can be watermarked with your custom logo or graphic.",
    learnMoreLink: "/features/custom-watermark",
    tutorialLink: "",
  },
  {
    title: "Custom invoice messages",
    image: customInvoiceMsg,
    text: "Greet and thank your customers personally on each photography invoice. Easily set a default message, and customize each invoice as needed.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Custom email delivery templates",
    image: emailTemplate,
    text: "We want the process of emailing invoices to be as quick and easy as possible. You can craft a default email message, use dynamic placeholders (for customer name, invoice link, etc), and send the email with your email application of choice.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Accept tips",
    image: tipping,
    text: "Make a few extra bucks! Choose whether to give your customers the option to include a tip when paying their invoice.",
    learnMoreLink: "",
    tutorialLink: "",
  },
  {
    title: "Invoice view history",
    image: invoiceViewImg,
    text: "Keep tabs on when and where your invoices have been viewed by accessing the complete view history of each invoice.",
    learnMoreLink: "",
    tutorialLink: "",
  },
];
