import React, { Component } from 'react'
import styled from 'styled-components'

import { colors } from 'Constants'
import DemoButton from './Buttons/DemoButton'

class DemoCTABlock extends Component {
  render() {
    return (
      <Wrapper>
        <SignupTitle>Want to see more?</SignupTitle>
        <ButtonWrapper>
          <DemoButton type="large" />
        </ButtonWrapper>
      </Wrapper>
    )
  }
}

export default DemoCTABlock

const Wrapper = styled.div`
  text-align: center;
  padding: 120px 20px;
  background-color: ${colors.primary};
  color: white;
  max-width: 100%;

  @media (max-width: 767px) {
    padding: 60px 20px;
  }
`

const SignupTitle = styled.h2`
  font-size: 54px;
  color: white;

  @media (max-width: 420px) {
    font-size: 32px;
  }
`

const ButtonWrapper = styled.div`
  margin: 60px auto 0;
`
