import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

class Tags extends Component {
  static propTypes = {
    tags: PropTypes.array
  }

  render() {
    return <Wrapper>Tags: {this.props.tags.join(', ')}</Wrapper>
  }
}

export default Tags

const Wrapper = styled.div`
  font-size: 15px;
  color: #747474;

  @media (max-width: 450px) {
    font-size: 13px;
  }
`
