import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ContentWrapper } from './_ContentWrapper'
import { colors } from 'Constants'

class SingleItem extends Component {
  static propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    imgSrc: PropTypes.string,
    imgStylesDisabled: PropTypes.bool
  }

  render() {
    const { title, description, imgSrc, imgStylesDisabled } = this.props

    return (
      <Wrapper>
        <Line />
        <Content>
          <CopyBlock>
            <Title>{title}</Title>
            <Copy>{description}</Copy>
          </CopyBlock>
          <ImageBlock>
            {imgStylesDisabled ? (
              <ImagePlain src={imgSrc} />
            ) : (
              <Image src={imgSrc} />
            )}
          </ImageBlock>
        </Content>
      </Wrapper>
    )
  }
}

export default SingleItem

const Wrapper = styled.div`
  background-color: ${colors.ltPrimaryBackground};
`

const Line = styled.div`
  border: 1px solid rgb(220, 230, 236);
  width: 40%;
  margin: 0 auto;
`

const Content = styled(ContentWrapper)`
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`

const CopyBlock = styled.div`
  flex: 1;
  max-width: 400px;
  margin-right: 20px;

  @media (max-width: 850px) {
    margin-right: 0;
  }
`

const ImageBlock = styled.div`
  width: 400px;
  max-width: 100%;
`

const Title = styled.h2`
  margin-top: 0;
  font-size: 28px;

  @media (max-width: 600px) {
    font-size: 22px;
  }
`

const Copy = styled.div`
  margin-top: 28px;
  margin-left: 30px;
  opacity: 0.9;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);

  @media (max-width: 600px) {
    margin-top: 18px;
    margin-left: 0;
    font-size: 16px;
  }
`

const ImagePlain = styled.img`
  max-width: 100%;
  @media (max-width: 850px) {
    margin-top: 24px;
  }
`

const Image = styled(ImagePlain)`
  box-shadow: 0 2px 9px 0 rgba(78, 78, 78, 0.5);
  border-radius: 6px;
`
