import styled from "styled-components";

export const Wrapper = styled.div`
  width: 650px;
  max-width: 90%;
  margin: 0 auto 60px;
`;

export const Title = styled.h1`
  font-size: 42px;

  @media (max-width: 900px) {
    font-size: 36px;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }

  @media (max-width: 460px) {
    font-size: 21px;
  }
`;

export const Content = styled.div`
  font-size: 17px;
  color: #333;
  line-height: 1.4;

  ul,
  ol {
    margin-left: 30px;
  }

  ol li {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    /* font-size: 18px; */
  }

  @media (max-width: 460px) {
    font-size: 16px;
  }
`;

export const ImgCentered = styled.img`
  width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "500px")};
  max-width: 100%;
  margin: 30px auto;
  display: block;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
`;

export const Divider = styled.div`
  height: 1px;
  border-bottom: 2px solid #dfdfdf;
  width: 300px;
  max-width: 80%;
  margin: 40px auto;
`;
