import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";

import Layout from "components/shared/Layout";
import customWatermarkImg from "assets/features/custom-watermark.png";
import PaidServiceCallout from "./PaidServiceCallout";

export default function CustomWatermarkTutorial() {
  return (
    <Layout>
      <Helmet title="Custom Watermark Tutorial | PhotoInvoice" />

      <h1>Tutorial: Custom Watermarks</h1>
      <Content>
        <img
          src={customWatermarkImg}
          alt="PhotoInvoice real estate photography custom watermark"
        />

        <p>
          Included in the Professional Edition of PhotoInvoice, custom
          watermarks give you the chance to customize the payment experience
          even further, and gives another opportunity to promote your logo and
          brand.
        </p>
        <p>
          All sample images are automatically watermarked upon upload, and with
          custom watermarks, you have complete control over the watermark
          graphic used.
        </p>

        <h2>On creating the perfect watermark...</h2>
        <p>
          PhotoInvoice uses sensible defaults to do its best with whatever image
          you provide as your custom watermark.
        </p>
        <p>
          That said, it can take a bit of trial and error to create a watermark
          image that suits your preferences. You are free to simply upload your
          logo as-is, but spending a bit more time fine-tuning your watermark
          file can make a big difference in the final result.
        </p>

        <PaidServiceCallout />

        <h2>General notes on high-quality watermarks</h2>
        <ol>
          <li>Watermark files must be PNG format and less than 1MB in size.</li>
          <li>Usually light-colored watermarks show up best.</li>
          <li>
            The amount of transparency/opacity of the watermark is based on the
            image itself. (PhotoInvoice does not apply any additional
            transparency when applying the watermark)
          </li>
          <li>
            For semi-transparent watermarks, it&apos;s especially important to
            use a white (or very light) colored image.
          </li>
          <li>
            Since most real estate photos are landscape, generally
            horizontal-oriented watermarks work best (rather than square or
            vertical).
          </li>
        </ol>

        <h2>How to experiment...</h2>
        <p>
          As you get started experimenting with your custom watermark, one thing
          to note is that{" "}
          <strong>
            changing your watermark will <em>not</em> impact previously-uploaded
            sample images
          </strong>
          . Samples are watermarked with whatever watermark image is on file at
          the time.
        </p>
        <ol>
          <li>
            If possible, choose a horizontal, light-colored logo. (files must be
            .png format and under 1MB)
          </li>
          <li>
            Upload the logo in your{" "}
            <a href="https://app.photoinvoice.com/settings/logo">
              watermark settings area
            </a>
            .
          </li>
          <li>
            Create a new invoice for testing purposes, and upload a couple
            photos from a recent real estate photography shoot.
          </li>
          <li>
            Click the &quot;View Invoice&quot; link to see the watermarked
            photos (note: watermarking can take a few moments, so refresh the
            page if you are not seeing the watermarked images right away.)
          </li>
          <li>
            Depending on the result, make changes to the watermark logo file,
            re-upload it, and then upload a new sample photo to check the
            results. Repeat as needed.
          </li>
        </ol>

        <h2>Watermark image variables to consider</h2>
        <p>
          As you experiment with your watermark, here are a few items worth
          considering:
        </p>
        <ul>
          <li>
            <strong>Logo color.</strong> Typically white logos show up best.
            This is especially true if you want want to decrease the opacity of
            the watermark so the photo underneath partially comes through.
          </li>
          <li>
            <strong>Opacity.</strong> For white logos, applying a ~60% opacity
            can add a nice touch.
          </li>
          <li>
            <strong>Orientation.</strong> Since real estate photography photos
            are typically landscape, using a horizontally-oriented logo often
            looks best.
          </li>
          <li>
            <strong>Background shadow.</strong> This helps the watermark to pop
            on the widest range of underlying photos.
          </li>
          <li>
            <strong>Size.</strong> Watermarks are applied at 70% width of the
            longest edge. So to increase or decrease the size of a watermark
            relative to the photo, you can include a wider transparent
            margin/border around the watermark logo in the watermark file you
            upload.
          </li>
        </ul>

        <PaidServiceCallout />
      </Content>
    </Layout>
  );
}

const Content = styled.div`
  box-sizing: border-box;
  width: 700px;
  max-width: 100%;
  margin: 0 auto 50px;
  padding: 0 20px;
  font-size: 17px;
  color: #3a3a3a;

  h2 {
    font-size: 28px;
    margin-top: 40px;
  }

  p {
    line-height: 1.5;
    margin-bottom: 20px;
  }

  img {
    max-width: 100%;
  }

  li {
    margin-left: 20px;
    margin-bottom: 10px;
    line-height: 1.3;
  }
`;
