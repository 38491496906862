import React from "react";

import Layout from "components/shared/Layout";
import { Wrapper, Title, Content, ImgCentered } from "styles/PageStyles";

// data & images
import articleData from "../grow-article-data.json";
import mapsResultImgSrc from "./google-map-results.jpg";

const GoogleMapsArticle = () => {
  const article = articleData.find((article) => article.slug === "google-maps");

  return (
    <Layout>
      <Wrapper>
        <Title>{article.title}</Title>
        <Content>
          <p>
            Want a quick task you can do in about 10 minutes that could easily
            land you extra shoots this year?
          </p>
          <p>
            Here is the general idea: when someone does a Google search that
            includes a city name (for instance, “real estate photography
            boise”), Google often includes a map at the top of the results page:
          </p>

          <ImgCentered
            src={mapsResultImgSrc}
            alt="Real Estate photography Google Maps results"
          />

          <p>
            Map results aren’t particularly useful for this search (no one
            really cares about the physical location of your office), but
            <em>Google doesn’t know that</em>. It simply sees a city name in the
            search text, and so it includes map results at the top.
          </p>
          <p>
            Because of this, adding your business location to Google Maps can be
            a quick way to show up at the top of the Google search results page,
            even if your main website SEO is still a work in progress.
          </p>
          <p>
            Adding your business to Google Maps is quick and easy. Head over to{" "}
            <a href="https://www.google.com/business/">
              https://www.google.com/business
            </a>{" "}
            and click <strong>Start Now</strong>. It will require a Google
            Account to proceed, but it’s free to create one if needed.
          </p>
          <p>
            While you’re at it, you might as well make sure your business is
            listed with any other local business directory type sites (Yelp,
            etc). The more places people can find you the better (both for SEO,
            and if someone happens to search on that directory directly).
          </p>
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default GoogleMapsArticle;
