import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "gatsby";

FeatureListItem.propTypes = {
  feature: PropTypes.object.isRequired,
};

export default function FeatureListItem({ feature }) {
  const {
    title,
    text,
    image,
    learnMoreLink,
    // tutorialLink, // not yet built
  } = feature;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Content>
        <Image>
          <img src={image} />
        </Image>
        <Info>
          <Text dangerouslySetInnerHTML={{ __html: text }} />

          {learnMoreLink && <Link to={learnMoreLink}>Learn More</Link>}
        </Info>
      </Content>

      <BottomDivider />
    </Wrapper>
  );
}

const Wrapper = styled.li`
  padding: 20px 0;
`;

const Content = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Image = styled.div`
  margin-right: 30px;

  img {
    width: 180px;
    border-radius: 6px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;

const Info = styled.div``;

const Title = styled.h3`
  margin: 0 0 30px;
  font-size: 28px;
`;

const Text = styled.p`
  margin-top: 0;
  font-size: 17px;
  line-height: 1.4;
`;

const BottomDivider = styled.div`
  width: 300px;
  margin: 50px auto 20px;
  height: 4px;
  background-color: #ececec;
`;
