import React from "react";
import styled from "styled-components";

import CustomerQuote from "components/shared/CustomerQuote";
import premierHouseToursLogo from "assets/home/logo-premier-house-tours.jpg";

export default function QuoteSection() {
  return (
    <Wrapper>
      <Content>
        <CustomerQuote
          author="Macoby @ Premier House Tours"
          quote="Wow- this is working great! I used to have about $3000 in unpaid
            invoices. I'm so grateful for this!"
          logo={premierHouseToursLogo}
        />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 50px 0 30px;

  @media (max-width: 700px) {
    padding: 20px;
  }
`;

const Content = styled.div`
  width: 1000px;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @media (max-width: 930px) {
    flex-direction: column;
    width: 700px;
  }
`;
